// Import styles
import( /* webpackChunkName: "styles" */ '../css/index.css');

// Import images
import( /* webpackChunkName: "logo_modelli" */ '../img/logo_modelli.svg');
import( /* webpackChunkName: "logo_modelli_trade" */ '../img/logo_modelli.svg');

// Import scripts
import( /* webpackChunkName: "lazysizes" */ 'lazysizes');
import( /* webpackChunkName: "lazysizes-bgset" */ 'lazysizes/plugins/bgset/ls.bgset');
import( /* webpackChunkName: "lazysizes-parentfit" */ 'lazysizes/plugins/parent-fit/ls.parent-fit');
import( /* webpackChunkName: "mmmenu-light" */ 'mmenu-light/dist/mmenu-light');

if (!window.HTMLPictureElement || document.msElementsFromPoint) {
  import( /* webpackChunkName: "lazyszies-polyfill-ie" */ 'lazysizes/plugins/respimg/ls.respimg');
}

const site = () => import(/* webpackChunkName: "site-bundle" */ './site.js');

// App main
site().then(({ default: siteApp }) => {
  window.siteApp = siteApp;
  window.siteApp.init();
});

window.onYouTubeIframeAPIReady = function () {
  window.siteApp.video.initIframe();
}
